<template>
  <div class="row register-container">
    <div class="background col-12"></div>
    <div class="login">
      <div class="sp-logo">
        <img
          src="https://www.sempeak.com/yuklemeler/imaj/logo.svg"
          alt=""
          width="150"
        />
      </div>
      <div class="heading">
        <h2>Wellcome</h2>
        <span>Log into sempeak</span>
      </div>
      <form action="">
        <div class="form-group">
          <input type="text" placeholder="name" v-model="name" />
          <input type="email" placeholder="E-mail" v-model="email" />
          <input type="password" placeholder="password" v-model="password" />
          <input type="password" placeholder="repeat password" />
          <input type="text" placeholder="Company name" v-model="company_name" />
        </div>

    
        <div class="create-or-login">
          <router-link tag="a" to="/login">LOGIN</router-link>
          <button @click.prevent="registerUser">Create Accounts</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
      name: "",
      email: "",
      password: "",
      company_name: "",
    };
  },
  methods: {

      
    registerUser() {
        
      this.$apollo.mutate({
        mutation: gql`
          mutation register($input:RegisterInput!){
            register(options:$input){
              errors {
                message               
              }
              user {
                id
                name
                email
                email_verified
                role
                blocked
                two_factor_enabled
              }
              user_company {
                company_name
              }
            }
          }
        `,
        variables: {
        input:{
          name: this.name,
          email: this.email,
          password: this.password,
          company_name: this.company_name,
          role: "admin",
          client: "web",
       
        },
        }
      });


    },
  },

};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.background {
  position: absolute;
  top: 0px;
  height: 100%;
  z-index: -1;
  background-color: #edeef2;
  width: 100%;
}
.login {
  position: absolute;
  left: 33%;
  top: 10vh;
  padding: 20px;
  width: 25%;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 0px 6px 1px #acaaaa;
}
.heading {
  margin-top: 20px;
}
.heading > h2 {
  font-size: 34px;
  letter-spacing: 0.25px;
  font-weight: 400;
  margin-bottom: 5px;
}
form {
  margin-top: 28px;
}
.form-group {
  display: flex;
  flex-direction: column;
}
.form-group > input {
  margin-bottom: 22px;
  height: 35px;
  border-top: unset;
  border-right: unset;
  border-left: unset;
  border-bottom: 1.6px solid #717276;
  background-color: #66666626;
  padding-left: 15px;
  border-radius: 5px 5px 0 0;
}
.form-group > input:focus {
  outline: none;
  border-bottom: 2px solid #27c26c;
}
.forgot-password {
  margin-top:28px;
  display: flex;
  justify-content: end;
}
.create-or-login {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
}
.create-or-login a {
  color: #27c26c;
  width: 115px;
  text-align: center;
  padding: 10px 5px;
  border-radius: 7px;
}
.create-or-login a:hover {
  background-color: #c4fed642;
}
.create-or-login button {
  padding: 15px;
  border: unset;
  border-radius: 22px;
  background-color: #157aff;
  color: white;
  font-size: 500;
  cursor: pointer;
}
a {
  text-decoration: none;

  font-weight: 500;
}

@media (max-width: 750px)  {

  .login{
  position: static;
    left: 0;
    top: 10vh;
    padding: 20px;
    width: auto;
    background: white;
    box-shadow: unset;
  }
  .background{
    display: none ;
  }
}
</style>